<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Contacts">
      <button
        @click="addContactPanelOpen = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Create Contact</span>
      </button>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex items-center justify-between">
          <div class="flex space-x-2">
            <button
              @click="selectTab('all')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'all',
                'hover:bg-gray-200': currentTab != 'all',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
              </svg>
              <span>All Contacts</span>
            </button>

            <button
              @click="currentTab = 'search'"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'search',
                'hover:bg-gray-200': currentTab != 'search',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex flex-1 justify-end">
            <input
              type="text"
              class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
              placeholder="Search contacts by name, addresses or people"
              v-model="searchTerm"
              @keydown.enter="searchContacts"
            />
            <button
              @click="searchContacts"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <svg class="stroke-current h-5 w-5" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </div>
        </div>
        <div v-if="viewState == 'Idle'">
          <div v-if="currentTab == 'all'">
            <vue-good-table
              class="mt-5"
              styleClass="vgt-table condensed"
              mode="remote"
              :rows="contacts"
              :columns="columns"
              :totalRows="totalRecords"
              @on-row-click="onContactsRowSelected"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :pagination-options="{
                enabled: true,
                perPage: pagingParams.limit,
                perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                dropdownAllowAll: false,
                setCurrentPage: pagingParams.page,
              }"
            />
          </div>
          <div v-if="currentTab == 'search'">
            <div v-show="searchState == 'Idle'">
              <h4 class="mt-5" v-if="searchTermWas && totalSearchResults > 0">{{ totalSearchResults }} search results for '{{ searchTermWas }}'</h4>
              <vue-good-table
                class="mt-5"
                mode="remote"
                styleClass="vgt-table condensed"
                :rows="searchResults"
                :columns="columns"
                :totalRows="totalSearchResults"
                :row-style-class="markRowAsSelected"
                @on-row-click="onContactsRowSelected"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :pagination-options="{
                  enabled: true,
                  perPage: searchPagingParams.limit,
                  perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: searchPagingParams.page,
                }"
              />
              <h5 class="text-center py-3" v-if="searchTermWas && searchResults.length == 0">No results for {{ searchTermWas }}</h5>
            </div>
            <div v-if="searchState == 'Searching'" class="flex items-center justify-center py-20">
              <Spinner :color="'text-black'" :size="10" />
            </div>
            <div v-if="searchState == 'Error'">
              <div class="mt-5 p-6 rounded bg-red-300">
                Something went wrong performing your search, please try again. If the problem persists, please contact your support team.
              </div>
            </div>
          </div>
        </div>
        <div v-if="viewState == 'Loading'" class="flex items-center justify-center py-20">
          <spinner :color="'text-black'" :size="10" />
        </div>
      </div>
    </div>
    <portal to="overlay-outlet"> </portal>

    <portal to="overlay-outlet">
      <panel
          :showing="addContactPanelOpen"
          @close="addContactPanelOpen = false"
          title="Create New Contact"
      >
        <AddNewContact/>
      </panel>
    </portal>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import AddNewContact from '@/components/Contacts/AddNew.vue'

export default {
  components: {
    AddNewContact,
    PageHeader,
    Spinner,
    Panel,
  },
  data() {
    return {
      viewState: "Idle",
      searchState: "Idle",
      currentTab: "all",
      searchTerm: "",
      searchTermWas: "",
      searchResults: [],
      totalSearchResults: 0,
      error: null,
      totalRecords: 0,
      pagingParams: {},
      searchPagingParams: {},
      contacts: [],
      columns: [
        {
          label: "Customer Ref No",
          field: "account_reference",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Contact No",
          field: "default_people",
          sortable: false,
          formatFn: this.formatDefaultTelephone,
        },
        {
          label: "Postcode",
          field: "default_address",
          sortable: false,
          formatFn: this.formatDefaultAddressToPostcode,
        },
        {
          label: "Customer",
          field: "is_customer",
          width: "100px",
          sortable: false,
          formatFn: this.formatBoolToYesNo,
        },
        {
          label: "Agent",
          field: "is_agent",
          width: "100px",
          sortable: false,
          formatFn: this.formatBoolToYesNo,
        },
        {
          label: "Supplier",
          field: "is_supplier",
          width: "100px",
          sortable: false,
          formatFn: this.formatBoolToYesNo,
        },
      ],
      addContactPanelOpen: false,
    };
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      this.pagingParams = this.$store.state.contactPaging;
      this.searchPagingParams = this.$store.state.contactSearchPaging;
      await this.loadContacts();

      this.searchTerm = this.$store.state.contactSearchTerm;
      await this.searchContacts();
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    selectTab: function(tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      this.$store.dispatch("setContactSearchTerm", "");
    },
    async searchContacts() {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      this.$store.dispatch("setContactSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.searchState = "Searching";

        this.$nextTick(() => {
          this.currentTab = "search";
        });

        let results = await this.ContactService.searchContact(this.searchTerm, this.searchPagingParams);

        this.searchResults = results.data;
        this.totalSearchResults = results.count;
        this.searchTermWas = this.searchTerm;
        this.searchState = "Idle";
      } catch (err) {
        console.error(err);
        this.searchState = "Error";
      }
    },
    async loadContacts() {
      let contactsResult = await this.ContactService.getContacts(this.pagingParams);

      this.contacts = contactsResult.data;
      this.totalRecords = contactsResult.count;
    },

    onContactsRowSelected(selectedRow) {
      this.$store.dispatch("setLastSelectedContact", selectedRow.row.contact_id);
      this.$router.push({
        name: "contacts-edit",
        params: { contactId: selectedRow.row.contact_id },
      });
    },

    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      if (this.currentTab == "search") {
        await this.searchContacts();
      } else {
        await this.loadContacts();
      }
    },

    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.currentPerPage, page: 1 });
      if (this.currentTab == "search") {
        await this.searchContacts();
      } else {
        await this.loadContacts();
      }
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
        page: 1,
      });
      if (this.currentTab == "search") {
        await this.searchContacts();
      } else {
        await this.loadContacts();
      }
    },

    markRowAsSelected: function(row) {
      return this.$store.state.lastSelectedContact == row.contact_id ? "bg-green-300" : "";
    },

    updatePagingParams(newProps) {
      if (this.currentTab == "search") {
        this.searchPagingParams = Object.assign({}, this.searchPagingParams, newProps);
        this.$store.dispatch("setContactSearchPaging", this.searchPagingParams);
      } else {
        this.pagingParams = Object.assign({}, this.pagingParams, newProps);
        this.$store.dispatch("setContactPaging", this.pagingParams);
      }
    },

    onColumnFilter(params) {
      console.log("onColumnFilter", params);
    },

    formatBoolToYesNo: function(value) {
      return value ? "Y" : "N";
    },

    formatDefaultAddressToPostcode: function(value) {
      return value ? value.postcode : "";
    },
    formatDefaultTelephone: function(value) {
      return value && value.telephone ? value.telephone : value && value.mobile ? value.mobile : "";
    },
  },
};
</script>
